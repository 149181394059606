var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "상세" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재해발생원인",
                    value: _vm.accData.accidentCause,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재발방지대책(공학적)",
                    value: _vm.accData.engineeringRecurrence,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "재발방지대책(관리적)",
                    value: _vm.accData.managementRecurrence,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-md-3 col-lg-3" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "환경안경팀 의견",
                    value: _vm.accData.managerOpinion,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [_c("c-upload", { attrs: { editable: false } })],
              1
            ),
          ]),
        ],
        2
      ),
      _c("br"),
      _c("c-table", {
        ref: "tableImpr",
        attrs: {
          title: "개선 목록",
          columns: _vm.gridImpr.columns,
          gridHeight: _vm.gridImpr.height,
          data: _vm.accData.accidentImproveModelList,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: false,
                          isImmShow: true,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "causesNo",
                          ibmTaskTypeCd: "ITT0000040",
                          ibmTaskUnderTypeCd: "ITTU000065",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }