<template>
  <div>
    <c-card title="상세" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-md-3 col-lg-3">
          <c-label-text title="재해발생원인" :value="accData.accidentCause"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-3">
          <c-label-text title="재발방지대책(공학적)" :value="accData.engineeringRecurrence"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-3">
          <c-label-text title="재발방지대책(관리적)" :value="accData.managementRecurrence"></c-label-text>
        </div>
        <div class="col-xs-12 col-md-3 col-lg-3">
          <c-label-text title="환경안경팀 의견" :value="accData.managerOpinion"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-upload 
            :editable="false">
          </c-upload>
        </div>
      </template>
    </c-card>
    <br>
    <c-table
      ref="tableImpr"
      title="개선 목록"
      :columns="gridImpr.columns"
      :gridHeight="gridImpr.height"
      :data="accData.accidentImproveModelList"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="false"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="causesNo"
            ibmTaskTypeCd="ITT0000040"
            ibmTaskUnderTypeCd="ITTU000065"
            @imprChange="imprChange"
          />
        </template>
      </template>
    </c-table>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cause-prevention-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
      }),
    },
  },
  data() {
    return {
      accData: {
        occurrenceDeptCd: '',
        accidentStatusCd: '',
        accidentCause: '',
        engineeringRecurrence: '',
        managementRecurrence: '',
        accidentImproveModelList: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'causeTypeCd',
            field: 'causeTypeCd',
            // 원인구분
            label: 'LBL00001560',
            align: 'center',
            style: 'width:15%',
            type: 'select',
            comboItems: [
              { code: 'ICDC000001', codeName: '기술적' },
              { code: 'ICDC000002', codeName: '교육적' },
              { code: 'ICDC000003', codeName: '관리적' },
            ],
            sortable: false,
          },
          {
            name: 'accidentCause',
            field: 'accidentCause',
            // 사고원인
            label: 'LBL00001561',
            align: 'left',
            type: 'text',
            style: 'width:50%',
            sortable: false,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL00001562',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                // 개선번호/진행상태
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:15%',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvingMeasures',
                field: 'improvingMeasures',
                // 개선대책
                label: 'LBL00001563',
                align: 'left',
                style: 'width:20%',
                type: 'text',
                sortable: false
              },
            ]
          },
        ],
        
        data: [],
        height: '315px'
      },
      editable: true,
      getUrl: '',
    };
  },
  computed: {
    companyInfo() {
      return this.accData.accidentBirthDate + '/' + this.accData.accidentSpotName + '/' + this.accData.accidentAge + '/' + this.accData.accidentSexName + '/' + this.accData.accidentLongevityCount;
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['accidentCause'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.getUrl = selectConfig.sop.iim.accident.process.prevention.get.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.getUrl, this.param.iimAccidentId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.accData =  _result.data;
        this.$emit('accidentName', this.accData.processName)
      },);
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['tableImpr'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
